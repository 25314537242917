<template>
  <div
    class="w-full h-max flex items-center h-[60px] overflow-hidden absolute bottom-0"
  >
    <img
      v-if="isRecording"
      alt="add"
      src="@/static/img/icon/close-micro.svg"
      class="cursor-pointer rounded-full"
      @mouseup="stopRecording"
    />
    <a-dropdown :trigger="['click']" v-else>
      <img
        alt="add"
        src="@/static/img/icon/phone-add.svg"
        class="cursor-pointer rounded-full"
        @click.prevent
      />
      <template #overlay>
        <div>
          <div
            v-for="item in items"
            :key="item.type"
            @click="handleClickUpload(item.type)"
            @mouseenter="hoveredItem = item.type"
            @mouseleave="hoveredItem = ''"
            :class="[
              hoveredItem === item.type ? 'bg-ems-main2' : 'bg-ems-gray800',
              'flex cursor-pointer h-[39px] w-[168px] flex items-center',
            ]"
          >
            <img :src="item.icon" alt="icon" class="pl-[8px] pr-[12px]" />
            <p
              :class="[
                hoveredItem === item.type
                  ? 'font-semibold text-white'
                  : 'font-normal text-ems-gray300',
                'text-[16px]',
              ]"
            >
              {{ item.text }}
            </p>
          </div>
        </div>
      </template>
    </a-dropdown>
    <div class="w-full relative">
      <a-input
        v-model:value="userName"
        :placeholder="isRecording ? '' : $t('coordinator.enter_messages')"
        class="bg-ems-gray600 border-none h-[45px] rounded-[80px] ml-[13px] text-white"
        @input="hasInput = $event.target.value.trim() !== ''"
        @keyup.enter="handleSendMessages"
      >
      </a-input>
      <div
        v-if="isRecording"
        class="flex space-x-[10px] items-center justify-center overflow-hidden absolute w-full top-0 left-[13px] h-[50px]"
      >
        <div v-for="(voice, entryIndex) in volumeArray" :key="entryIndex">
          <div
            class="min-h-[15px] w-[5px] rounded-full bg-ems-gray500 max-h-[40px]"
            :style="{ height: voice * 2 + 'px' }"
          ></div>
        </div>
        <p class="text-ems-main2">{{ formattedTime }}</p>
      </div>
    </div>
    <div
      class="bg-ems-main2 w-[38px] h-[38px] rounded-full flex justify-center items-center flex-shrink-0 ml-[20px]"
    >
      <img
        v-if="hasInput || isRecording"
        alt="add"
        src="@/static/img/icon/send.svg"
        class="h-[20px] cursor-pointer"
        @click="handleSendMessages"
      />
      <img
        v-else
        alt="add"
        src="@/static/img/icon/mic-open.svg"
        class="h-[24px] cursor-pointer"
        @mousedown="startRecording"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, computed } from 'vue';
import VueTypes from 'vue-types';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import ConstantAPI from '@/config/ConstantAPI';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps({
  name: VueTypes.string.def(''),
  message: VueTypes.string.def(''),
});
const { state, dispatch } = useStore();
const itemValue = computed(() => state.coordinator.itemValue || '');
const hoveredItem = ref('');
const userName = ref('');
const urlImage = ref('');
const hasInput = ref(false);
const mediaRecorder = ref(null);
const audioChunks = ref([]);
const isRecording = ref(false);
const audioURL = ref('');
const audioFile = ref(null);
const isSendFile = ref(false);
const mediaType = ref('');
const seconds = ref(0);
const minutes = ref(0);
let intervalId;
let analyser = null;
const volumeArray = ref(Array(18).fill(0));
const items = [
  {
    type: 'video',
    icon: require('@/static/img/icon/send-video.svg'),
    text: t('coordinator.send_video'),
  },
  {
    type: 'image',
    icon: require('@/static/img/icon/send-image.svg'),
    text: t('coordinator.send_image'),
  },
];
const selectFile = (type) => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    if (type === 'image') {
      input.accept = 'image/*';
      mediaType.value = 'image';
    } else {
      input.accept = 'video/*';
      mediaType.value = 'video';
    }

    input.onchange = (e) => {
      const file = e.target.files[0];
      resolve(file);
    };

    input.oncancel = () => {
      resolve(null);
    };
    setTimeout(() => {
      input.onblur = () => {
        resolve(null);
      };
    }, 0);

    input.click();
  });
};

const handleClickUpload = async (type) => {
  try {
    const file = await selectFile(type);
    if (file) {
      isSendFile.value = true;
      customRequest(file);
    } else {
      isSendFile.value = false;
    }
  } catch (error) {
    console.error('Error during file selection', error);
  }
};

watch(userName, (newValue) => {
  hasInput.value = newValue.trim() !== '';
});
const handleSendMessages = () => {
  if (userName.value) {
    let mess = {
      receiverId: itemValue.value.otherInfo.peerId,
      receiverType: itemValue.value.otherInfo.peerType,
      messageType: isSendFile.value ? 'file' : 'text',
      content: userName.value,
      mediaType: mediaType.value,
      url: urlImage.value,
      length: seconds.value,
      forwarded: false,
    };
    dispatch('coordinator/sendMessages', mess).then((data) => {
      userName.value = '';
      isSendFile.value = null;
      (mediaType.value = null), (urlImage.value = null);
      stopTime();
    });
  }
  if (isRecording.value) {
    sendRecording();
  }
};

const customRequest = async (file) => {
  const fmData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  fmData.append('file', file);
  fmData.append('rootPath', 'image');
  try {
    const res = await DataService.upload(
      ConstantAPI.upload_service.UPLOAD,
      fmData,
      config
    );
    checkResponse(res, () => {
      userName.value = ' ';
      urlImage.value = res.data.url;
      handleSendMessages();
    });
  } catch (err) {
    console.log(err);
  }
};

const formattedTime = computed(() => {
  const formattedMinutes = String(minutes.value).padStart(2, '0');
  const formattedSeconds = String(seconds.value).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
});
const startTime = () => {
  if (intervalId) return;

  intervalId = setInterval(() => {
    if (seconds.value === 59) {
      seconds.value = 0;
      minutes.value += 1;
    } else {
      seconds.value += 1;
    }
  }, 1000);
};

const stopTime = () => {
  clearInterval(intervalId);
  intervalId = null;
  seconds.value = 0;
  minutes.value = 0;
  isRecording.value = false;
};

const startRecording = () => {
  startTime();
  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream) => {
      if (mediaRecorder.value && mediaRecorder.value.state === 'recording') {
        mediaRecorder.value.stop();
      }
      const audioContext = new AudioContext();
      analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      analyser.fftSize = 256;
      audioChunks.value = [];
      analyzeVolume();

      mediaRecorder.value = new MediaRecorder(stream);
      mediaRecorder.value.ondataavailable = (event) => {
        audioChunks.value.push(event.data);
      };
      mediaRecorder.value.onstop = () => {
        const audioBlob = new Blob(audioChunks.value, { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioURL.value = audioUrl;
        audioFile.value = new File([audioBlob], 'recording.mp3', {
          type: 'audio/mpeg',
        });
        audioChunks.value = [];
        isSendFile.value = true;
        mediaType.value = 'audio';
        if (isRecording.value) {
          customRequest(audioFile.value);
        }
        if (audioContext) {
          audioContext.close();
        }
      };
      mediaRecorder.value.start();
      isRecording.value = true;
    })
    .catch((error) => {
      console.error('Lỗi khi yêu cầu quyền truy cập microphone:', error);
    });
};

const analyzeVolume = () => {
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  const analyze = () => {
    setTimeout(() => {
      analyser.getByteFrequencyData(dataArray);
      const averageVolume =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
      volumeArray.value.push(Math.floor(averageVolume));
      if (volumeArray.value.length > 18) {
        volumeArray.value.shift();
      }
      analyze();
    }, 200);
  };
  analyze();
};

const sendRecording = () => {
  if (mediaRecorder.value && isRecording.value) {
    mediaRecorder.value.stop();
    volumeArray.value = Array(18).fill(null);
  }
};
const stopRecording = () => {
  if (mediaRecorder.value && isRecording.value) {
    mediaRecorder.value.stop();
    isRecording.value = false;
    volumeArray.value = Array(18).fill(null);
    seconds.value = 0;
    minutes.value = 0;
  }
};
</script>
