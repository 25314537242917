<template>
  <div class="flex items-center relative" :style="{ height: `${windowHeight-240}px` }">
    <div
      class="w-full chat-mess rounded-lg h-[98%] relative"
    >
      <MiddleChat :activeKey="activeKey"/>
      <BottomChat />
    </div>
  </div>
</template>

<script setup>
import {defineProps } from 'vue';
import MiddleChat from './components/middle-chat.vue';
import BottomChat from './components/bottom-chat.vue';
import VueTypes from 'vue-types';
import {useWindowHeight} from '@/util/common-utils';
const { windowHeight } = useWindowHeight();
const props = defineProps({
  activeKey: VueTypes.string.def(''),
});
</script>
<style lang="scss">
.chat-mess .ant-input-affix-wrapper > input.ant-input {
  background: #2B2A3A;
  color: #FFFFFF;
}
.chat-mess .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #F7F0F7;
}
</style>
